/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect, useRef } from 'react'
import Helmet from 'react-helmet'
import { graphql, Link, useStaticQuery } from 'gatsby'
import axios from 'axios'
import CryptoJS from 'crypto-js'
import PerfectScrollbar from 'perfect-scrollbar'
import { Plupload } from '@renderbus/common/utils'
import { useScroll } from '../../../common/hooks'
import { SEO } from '@renderbus/common/components'
import { RCBanner } from '../molecules/banner'
import { Exibition } from '../molecules/render-contest/exibition'
import {
  students,
  professions,
  wanhuaSpecial,
  style3D,
  mostPopular,
} from '../molecules/render-contest/exibition/utils.js'
import {
  RCSignFormContainer,
  PageContainer,
  IndexSection,
  RCHeader,
  HeaderLogo,
  HeaderItem,
  SignButton,
  NavigateMenuIcon,
  NavigateMenu,
  NavigateMenuItem,
  RCOrganization,
  Organizer,
  CoOrganizer,
  Sponsor,
  SupportUnits,
  RCLeftDays,
  RCStyle,
  ImgWrapper,
  Billboard2,
  RCWordsSection,
  RCDateSection,
  RCGroupSection,
  RCAwardSection,
  LeftArrow,
  RightArrow,
  RCJudgesSection,
  RCContentSection,
  RCDesignSection,
  RCQASection,
  RCFormSection,
  RCSignForm,
  RCFormItem,
  RCLabel,
  RCInput,
  RCRadioContainer,
  RCRadio,
  RCCheckbox,
  RCAgreements,
  RCSelect,
  RCOptionContainer,
  RCSelectIcon,
  RCSelectContainer,
  RCOption,
  RCButton,
  RCUploadContainer,
  StudentCardContainer,
  AwardType,
  ProfileContainer,
  Profile,
  LeftTagsContainer,
  Tag,
  RCDialogContainer,
  PageFooter,
  RCResultSection,
} from './rc.atom'

import GuianIcon from '../images/render-contest-2023/gui@2x.png'
import GuiYang from '../images/render-contest-2023/guiyang@2x.png'
import RayvisionIcon from '../images/render-contest-2023/rayvision@2x.png'
import RenderbusIcon from '../images/render-contest-2023/renderbus@2x.png'
import QingjiaoIcon from '../images/render-contest-2023/qing@2x.png'
import ThreeDCatIcon from '../images/render-contest-2023/3dcat@2x.png'
import YunIcon from '../images/render-contest-2023/yun@2x.png'
import EcoPlantsIcon from '../images/render-contest-2023/echo-plants@2x.png'
import ReallusionIcon from '../images/render-contest-2023/reallusion@2x.png'
import D5Icon from '../images/render-contest-2023/d5@2x.png'
import ChoosIcon from '../images/render-contest-2023/chaos@2x.png'
import KeniIcon from '../images/render-contest-2023/keni@2x.png'
import Style3DIcon from '../images/render-contest-2023/style3d@2x.png'
import FusionIcon from '../images/render-contest-2023/fusion@2x.png'
import SugonIcon from '../images/render-contest-2023/sugon@2x.png'
import SectionTitleIcon from '../images/render-contest-2023/title_bg@2x.png'
import WordsBgImg from '../images/render-contest-2023/yin_bg@2x.png'
import AddFileImg from '../images/render-contest-2023/upload@2x.png'
import UploadSuccessIcon from '../images/render-contest-2023/success@2x.png'
import UploadFailIcon from '../images/render-contest-2023/fail@2x.png'
import ProfessionalIcon from '../images/render-contest-2023/professional@2x.png'
import StudentIcon from '../images/render-contest-2023/student@2x.png'
import Mu from '../images/render-contest-2023/mu@2x.png'
import MuHover from '../images/render-contest-2023/mu_hover@2x.png'
import Gao from '../images/render-contest-2023/gao@2x.png'
import GaoHover from '../images/render-contest-2023/gao_hover@2x.png'
import Zhou from '../images/render-contest-2023/zou@2x.png'
import ZhouHover from '../images/render-contest-2023/ben_hover@2x.png'
import Shen from '../images/render-contest-2023/shen@2x.png'
import ShenHover from '../images/render-contest-2023/shen_hover@2x.png'
import Shi from '../images/render-contest-2023//shi@2x.png'
import Wei from '../images/render-contest-2023/wei@2x.png'
import Gaohan from '../images/render-contest-2023/gaohanwei@2x.png'
import Deng from '../images/render-contest-2023/deng@2x.png'
import Peng from '../images/render-contest-2023/pen@2x.png'
import Zhao from '../images/render-contest-2023/zhao@2x.png'
import Yu from '../images/render-contest-2023/yu@2x.png'
import Duan from '../images/render-contest-2023/duan@2x.png'
import DownloadImg from '../images/render-contest-2023/download@2x.png'
import GoImg from '../images/render-contest-2023/go@2x.png'
import PauseImg from '../images/render-contest-2023/play@2x.png'
import QRImg from '../images/render-contest-2023/qr@2x.png'
import PoliceIcon from '../../../common/components/footer/images/police.png'
import SlogoIcon from '../images/render-contest-2023/slogan@2x.png'

function RenderContest() {
  const headerItemList = [
    {
      label: '赛事详情',
      link: '#rc-time',
      left: '15.47vw',
    },
    {
      label: '赛事资料',
      link: '#rc-content',
      left: '4.79vw',
    },
    {
      label: '常见问题',
      link: '#rc-qa',
      left: '5.15vw',
    },
    {
      label: '首届回顾',
      link: 'https://www.rayvision.com/rrc.html',
      rel: 'nofollow',
      target: '_blank',
      left: '5.21vw',
    },
  ]
  const mobileMenuItemList = [
    {
      label: '赛果公布',
      link: '#rc-result',
    },
    {
      label: '大赛引言',
      link: '#rc-words',
    },
    {
      label: '大赛时间',
      link: '#rc-time',
    },
    {
      label: '大赛组别',
      link: '#rc-group',
    },
    {
      label: '大赛奖品',
      link: '#rc-award',
    },
    {
      label: '评委阵容',
      link: '#rc-judges',
    },
    {
      label: '大赛内容',
      link: '#rc-content',
    },
    {
      label: '设计说明',
      link: '#rc-design',
    },
    {
      label: '常见问题',
      link: '#rc-qa',
    },
    {
      label: '返回顶部',
      link: '#rc-top',
    },
  ]
  const supportUnits = [
    '欧特克软件(中国)有限公司',
    '中国电影美术学会CG艺术专委会',
    '中央美术学院国际数字艺术研究中心',
    '中国传媒大学动画与数字艺术学院',
    '广州美术学院视觉艺术设计学院',
    '中国美术学院创新设计学院',
    '西安美术学院影视动画系',
    '上海工艺美术学会数字艺术设计专业委员会',
    'wuhu动画人空间',
    '哔哩哔哩寻光',
    'CG模型网',
  ]
  const leftUnits = [
    '欧特克软件(中国)有限公司',
    '中央美术学院国际数字艺术研究中心',
    '广州美术学院视觉艺术设计学院',
    '西安美术学院影视动画系',
    'wuhu动画人空间',
    'CG模型网',
  ]
  const rightUnits = [
    '中国电影美术学会CG艺术专委会',
    '中国传媒大学动画与数字艺术学院',
    '中国美术学院创新设计学院',
    '上海工艺美术学会数字艺术设计专业委员会',
    '哔哩哔哩寻光',
  ]
  const words = [
    [
      '算联视界，元生万象',
      '其大无外，其小无内',
      '与其天马行空，不如造个星空',
      '以热爱为名，以灵感为翼',
      '在这里，让想象发生',
    ],
    ['造一艘游牧星际的飞船', '造一幅描摹苍穹的画卷'],
    ['这个平台，等你来挥洒创意', '这个平台，等你来肆意造梦', '这个平台，等你来绽放异彩'],
    [
      '瑞云官方流量扶持，大V媒体精准曝光',
      '高视野的行业交流，更多的业界合作机会',
      '用作品为自己代言',
      '这是属于你的高光时刻',
    ],
  ]
  const infoSourceOptions = [
    'Renderbus相关平台',
    'CG世界相关平台',
    '3DCAT相关平台',
    '七点GAME相关平台',
    '青椒云相关平台',
    'B站相关平台',
    'D5相关平台',
  ]
  const positionOptions = [
    '首席执行官（CEO）',
    '首席信息官（CIO）',
    '首席技术官（CTO）',
    '顾问',
    '数据科学家',
    '开发者/编程人员',
    '工程师',
    '电视/视频编辑',
    '平面设计师/动画师',
  ]
  const businessOptions = [
    '航空航天',
    '建筑/工程/建设',
    '汽车',
    '国防/政府部分',
    '金融服务业（FSI',
    '医疗保健',
    '高等教育/科研',
    'IT 服务业',
    '制造业',
    '媒体/娱乐',
    '石油和天然气/能源',
    '医疗影像/生命科学仪器',
    '实体零售/电商',
    '超算中心',
    '电信',
    '其他',
  ]
  const awardGroupMap = new Map([
    [
      '专业组',
      [
        {
          title: '冠军1人',
          value: '13W+',
          other: [
            '• 现金5W元',
            '• 科尼云高主频设计系统ultra版（价值45581元）',
            '• iClone 8 + Character Creator 4 专业动画组合（价值6286元）',
            '• 3年PBRMAX大师版账号（价值6000元）',
            '• Chaos V-Ray Premium 年度版（价值5964元）、Chaos Corona Premium年度版（价值3831元）、Chaos Enscape Fixed-seat license年度版（价值3985元）',
            '• D5渲染器专业版 1年 + 定制周边礼包（价值2500元）',
            '• Style3D Atelier 、Style3D Meta 正式版一年使用权限、Style3D 官方市场资产库使用权限一年（价值11140元)',
          ],
        },
        {
          title: '亚军3人',
          value: '5W+',
          other: [
            '• 现金3W元',
            '• iClone 8 基础组合 + MD Motions（价值5579元）',
            '• PBRMAX大师版账号（价值4000元）',
            '• Chaos V-Ray Solo版6个月（价值2003元）, Chaos Corona Solo版6个月（价值1436元）， Chaos Enscape Fixed-seat license3个月期（价值996元）',
            '• D5渲染器专业版  半年 + 定制周边礼包（价值1500元）',
            '• Style3D Atelier 、Style3D Meta 正式版一年使用权限、Style3D 官方市场资产库使用权限一年（价值11140元)',
          ],
        },
        {
          title: '季军6人',
          value: '2W+',
          other: [
            '• 现金2W元',
            '• Character Creator 4 基础组合 + Headshot 插件（价值3486元）',
            '• 1年PBRMAX大师版账号（价值2000元）',
            '• D5渲染器专业版  月订阅 + 定制周边礼包（价值500元）',
          ],
        },
      ],
    ],
    [
      '学生组',
      [
        {
          title: '冠军1人',
          value: '6W+',
          other: [
            '• 现金3W元',
            '• 科尼云高性能渲染系统（价值12410元）',
            '• iClone 8 + Character Creator 4 专业动画组合（价值6286元）',
            '• 3年PBRMAX大师版账号（价值6000元）',
            '• Chaos V-Ray Education年度版（价值1200元），Chaos Enscape Education年度版',
            '• D5渲染器专业版 1年 + 定制周边礼包（价值2500元）',
            '• Style3D Atelier 、Style3D Meta 正式版一年使用权限、Style3D 官方市场资产库使用权限一年（价值11140元)',
          ],
        },
        {
          title: '亚军3人',
          value: '4W+',
          other: [
            '• 现金2W元',
            '• iClone 8 基础组合 + MD Motions（价值5579元）',
            '• PBRMAX大师版账号（价值4000元）',
            '• D5渲染器专业版  半年 + 定制周边礼包（价值1500元）',
            '• Style3D Atelier 、Style3D Meta 正式版一年使用权限、Style3D 官方市场资产库使用权限一年（价值11140元)',
          ],
        },
        {
          title: '季军6人',
          value: '1W+',
          other: [
            '• 现金1W元',
            '• Character Creator 4 基础组合 + Headshot 插件(价值3486元)',
            '• 1年PBRMAX大师版账号(价值2000元)',
            '• D5渲染器专业版  月订阅 + 定制周边礼包(价值500元)',
          ],
        },
      ],
    ],
    [
      '万生华态特别奖',
      [
        {
          title: '万生华态特别奖1人',
          explain: '作品使用了万生华态数字资产，即有机会获得万生华态提供的特别奖',
          class: 'echo',
          other: [
            '• PBRMAX专业版1年（价值2680元）',
            '• 额外PBRMAX EP点数（支付EP点数可购买数字资产）（价值1000元）',
            '• 特邀产品体验官、KOC塑造、流媒体平台投流扶持（价值1000元）',
            '• EcoPlants周边大礼包',
          ],
        },
      ],
    ],
    [
      'Style3D特别奖',
      [
        {
          title: 'Style3D特别奖1人',
          explain: '作品使用了Style3D Atelier 或Style3D Meta软件，即有机会获得Style3D提供的特别奖',
          class: 'style-3d',
          other: [
            '• Style3D Atelier 正式版一年使用权限（价值1400元）',
            '• Style3D Meta 正式版一年使用权限（价值1W元)',
            '• Style3D 官方市场资产库使用权限一年（数十万个面辅料素材、数万个服装款式廓形、数千个数字模特配套资产）',
            '• 入驻Style3D官方人才库，获取个人IP扶持、内容曝光、商业资源和创作奖金等',
            '• Style3D官方大礼包',
          ],
        },
      ],
    ],
    [
      '最佳人气奖',
      [
        {
          title: '最佳人气奖2人',
          explain: '最终入围作品将进行网络公开投票，每组得票最高者获得最佳人气奖',
          class: 'popular',
          other: [
            '• 现金5000元',
            '• 1年PBRMAX专业版账号（价值599元）',
            '• 专业组：Chaos V-Ray Premium 年度版（价值5964元）、Chaos Corona Premium年度版（价值3831元）、Chaos Enscape Fixed-seat license年度版（价值3985元）/ 学生组：Chaos V-Ray Education年度版（价值1200元）、Chaos Enscape Education年度版',
            '• D5专业版 月订阅(价值200元)',
          ],
        },
      ],
    ],
    [
      '更多荣誉',
      [
        {
          title: '更多荣誉',
          class: 'more-honor',
          other: [
            '• 大赛奖杯+荣誉证书',
            '• 瑞云全渠道、专业媒体的流量曝光',
            '• 受邀采访、采访文章将在各大媒体平台曝光宣传',
            '• 获得直播机会、和广大CG爱好者分享制作经验和心得',
          ],
        },
      ],
    ],
  ])
  const mainJudgesList = [
    {
      name: '穆智飞',
      img: Mu,
      imgHover: MuHover,
      shortIntro: ['概念艺术指导\\制作设计师\\动画视效指导', '>>'],
      intro: [
        '概念艺术指导、制作设计师、动画视效指导',
        '中国电影艺术学会CG艺术委员会主任',
        '中央美术学院电影视效⼯作室专业导师',
      ],
    },
    {
      name: '高枫',
      class: 'gao-feng',
      img: Gao,
      imgHover: GaoHover,
      shortIntro: ['资深视效总监、视效制片人', '中国电影美术学会CG艺...', '>>'],
      intro: [
        '资深视效总监、视效制片人',
        '中国电影美术学会CG艺术专委会秘书长',
        '拥有13年以上影视特效制作、团队及项目管理经验。参与作品有《长津湖》《紧急救援》《碟中碟5》《007：幽灵党》《神奇动物在哪里》等',
      ],
    },
    {
      name: '邹琼',
      img: Zhou,
      imgHover: ZhouHover,
      shortIntro: ['瑞云科技总经理', '业界领先的云渲染系统及高性能文件传输系统开创者'],
      intro: ['瑞云科技总经理', '业界领先的云渲染系统及高性能文件传输系统开创者'],
    },
    {
      name: '沈映',
      img: Shen,
      imgHover: ShenHover,
      shortIntro: ['欧特克传媒娱乐行业大中华区技术专家', '>>'],
      intro: [
        '欧特克传媒娱乐行业大中华区技术专家',
        '有着丰富的行业技术背景，涉足三维动画行业多年，目前主要支持欧特克三维动画软件产品的相关技术销售工作。',
      ],
    },
  ]
  const expertList = [
    {
      name: '史叶',
      img: Shi,
      intros: {
        shortIntro: ['上海开放大学时尚学院特聘讲师'],
        total: ['上海开放大学时尚学院特聘讲师'],
      },
    },
    {
      name: '魏春明',
      img: Wei,
      intros: {
        shortIntro: ['上海工艺美术学会数字艺术设计专业委员会副主任'],
        total: ['上海工艺美术学会数字艺术设计专业委员会副主任'],
      },
    },
    {
      name: '于朕',
      img: Yu,
      intros: {
        shortIntro: ['中国美术学院创新设计学院副院长', '>>'],
        total: [
          '中国美术学院创新设计学院副院长，中国美术学院科艺融合研究中心 副主任，美国人工智能与艺术学会（AAIAA） 副秘书长',
        ],
      },
    },
    {
      name: '高汉威',
      img: Gaohan,
      intros: {
        shortIntro: ['韩国国立首尔大学设计学博士，韩国设计协会会员', '>>'],
        total: [
          '韩国国立首尔大学设计学博士，韩国设计协会会员，广州美术学院视觉艺术设计学院专任教师、多年从事数字美术、多媒体及数字娱乐方面的教学研究工作。',
        ],
      },
    },
    {
      name: '邓强',
      img: Deng,
      intros: {
        shortIntro: ['西安美术学院影视动画系副主任，设计学博士。', '>>'],
        total: [
          '西安美术学院影视动画系副主任，设计学博士。Autodesk M&E 三维动画教育专家(China)、Autodesk 3dsmax国际讲师、中国美术家协会会员、动画导演。',
        ],
      },
    },
    {
      name: '段雯锴',
      img: Duan,
      intros: {
        shortIntro: ['中国传媒大学动画与数字艺术学院教师、动画导演、动画创作人。', '>>'],
        total: [
          '中国传媒大学动画与数字艺术学院教师、动画导演、动画创作人。带领团队创作的先行短片《沉默之蓝：序》已在全世界超过40个动画电影节中获奖入围',
        ],
      },
    },
    {
      name: '彭程',
      img: Peng,
      intros: {
        shortIntro: ['万生华态CEO、中国科技艺术新生代领军人物'],
        total: ['万生华态CEO、中国科技艺术新生代领军人物'],
      },
    },
    {
      name: '赵树森',
      img: Zhao,
      intros: {
        shortIntro: ['Chaos 3D专家'],
        total: ['Chaos 3D专家'],
      },
    },
  ]
  const contentTags = ['评选标准', '技术支持', '赛事交流', '获奖通知', '相关权益']
  const standarList = [
    {
      title: '技术性',
      text: 'CG软件使用专业、熟练，模型、材质、渲染等制作质量优秀',
    },
    {
      title: '创意性',
      text: '作品富有新意和想象力，给人耳目一新的视觉感受',
    },
    {
      title: '艺术性',
      text: '画面表达流畅，视觉效果突出，整体设计具有美感和艺术价值',
    },
  ]
  const techSupports = [
    {
      title: 'Renderbus瑞云渲染',
      subTitle: 'Renderbus为参赛者作品免费提供云渲染服务',
      dowloadLink: {
        text: 'Renderbus下载',
        link: 'https://www.renderbus.com/download.html',
      },
      aboutLink: {
        text: '了解Renderbus',
        link: 'https://www.renderbus.com/',
      },
    },
    {
      title: '青椒云云端图形工作站',
      subTitle: '青椒云为参赛者创作免费提供高配版云桌面',
      dowloadLink: {
        text: '青椒云下载',
        link: 'https://www.qingjiaocloud.com/download/',
      },
      aboutLink: {
        text: '了解青椒云',
        link: 'https://www.qingjiaocloud.com/',
      },
    },
    {
      title: '3DCAT元宇宙实时渲染云',
      subTitle: '3DCAT实时渲染云平台为入围参赛作品免费提供云端实时渲染展示服务',
      aboutLink: {
        text: '了解3DCAT',
        link: 'https://www.3dcat.live/',
      },
    },
    {
      title: '万生华态',
      subTitle:
        '参赛者可在参赛期间免费使用万生华态数字资产进行创作\n（报名参赛后可免费使用数字资产）',
      aboutLink: {
        text: 'PBRMAX数字资产库',
        class: 'echo',
        link: 'https://pbrmax.com/?activeIndex=0&query=%7B%22pageNum%22%3A1%7D',
      },
      useLink: {
        text: '使用教程',
        link:
          'https://mp.weixin.qq.com/s?__biz=Mzg5MjU0MzkxNw==&mid=100004795&idx=1&sn=dd4a3b839c43906670206429a7970aa6&chksm=403d2579774aac6f909ae2019e316f580c5fa5eb2fe61e475cfc5f0014b6e822a474c9fdc9d6#rd',
      },
    },
  ]
  const awardNotices = [
    '1.现金部分奖金均为税前金额，将由主办方代扣代缴个人所得税，获奖通知将在最终结果公示后7个工作日内通过邮件方式发送至获奖作者参赛报名时预留的邮箱地址，请确认提供真实有效的联系方式。',
    '2.获奖作者在活动结束后请注意查收邮件，并根据邮件提示内容及时配合主办方完成作品收集及奖品发放等工作。',
    '3.若获奖作者在限定时间内对获奖通知未做回应，则视为放弃奖项，由其他参赛作者取代。',
    '4.主办方将在比赛结束后安排奖品发放事宜，同一获奖作者可获得多个奖项（人气奖、特别奖）。',
    '5. 最终赛果将在主办网站、协办网站等平台进行公示，并通过各媒体渠道进行曝光宣传。',
  ]
  const relatedProfitList = [
    '1. 所有参赛者向大赛提交的参赛作品需拥有独立完整的著作权，或已经取得著作权人的合法授权。',
    '2. 主办方及合作方拥有对全部参赛作品进行展示、报道、宣传及用于市场活动的权利，参赛者保留对其作品的著作权。主办方及合作方有权将用于市场活动的作品加入赛事水印。',
    '3. 作品一经提交，将视为参赛者同意并遵守比赛相关规定，若作品在商业使用中其著作权存在争议，主办方不承担因作品侵犯他人（或单位）的权利而产生的法律责任，由提供作品的参赛者承担全部法律责任。',
    '4. 对存在争议的作品，大设计奖组委会有权保留甚至撤销该作品的参赛资格。如获奖作品存在知识产权等争议，大设计奖组委会有权撤销获奖资格，召回奖状、奖杯、奖品等荣誉。',
    '5. 本次大赛最终解释权归主办方瑞云科技所有。',
  ]
  const designInfo = new Map([
    [
      '参赛须知',
      [
        '1. 本次大赛不限渲染方式，不限渲染器；',
        '2. 参赛请先填写报名表，并下载大赛资料获取3D动画模板，以模板为基础进行创作；',
        '3. 成功报名后，参赛者会收到参赛邮件，邮件内将提供Renderbus渲染券、青椒云代金券与万生华态免费数字资产获取方式，参赛者可提前注册好平台账号，按需免费使用；',
        '4. 发布作品：作品发布至B站，视频标题：“作品名| 第二届瑞云渲染动画创作大赛”，并在视频简介中进行作品描述，标签#瑞云渲染大赛#关键词，评论区@官号“Renderbus瑞云渲染”；',
        '5. 提交参赛：需要按照参赛邮件内的要求提交作品，作品命名格式为“作品名_作者名”，作者名务必要与报名时填写的姓名一致。',
        '6. 诚信参赛，不弄虚作假，不恶意侵占比赛资源，若有违反，主办方将追回侵占资源费用且追究法律责任',
      ],
    ],
    [
      '作品提交内容',
      [
        '1) 本次参赛作品可使用任何3D软件进行创作，请以分辨率1920*1080尺寸，每秒60帧画面进行渲染，总长7秒整（总共60fps x 7s = 420帧）；',
        '2) 画面中应只含有作品本身画面，注意不要出现不相干的水印；',
        '3) 不要做黑场、白场、渐入；',
        '4) 作品可包含音效，但不要使用大段背景音乐；',
        '5) 最终提交的作品必须是使用编码：H.264输出的视频',
      ],
    ],
    [
      '3D场景规格',
      [
        '1) 请以我们提供的FBX或USD文件来制作，以大赛提供的范例视频为主要参考标准；',
        '2) 创作时必须保证最终圆形外轮廓的大小、位置保持不变（和原FBX或USD一致），并使其贯穿整个作品；其他地方可任意发挥，如果需要可以在场景内添加任何东西！',
        '3) 场景内的相机动画、相机fov必须保持不变；',
      ],
    ],
  ])
  const qaList = [
    ['Q1：参赛需要收费吗？', 'A：不需要。'],
    [
      'Q2：一个人/团队可以报多少个组别？可以提交多少个作品？',
      'A：每人/每个团队仅可报名一个组别参赛，且只允许提交一个作品，不允许一个人/团队报名多个组别。',
    ],
    [
      'Q3：一个人/团队可以获得多个奖项吗？',
      'A：可以，除了获得各自组别的奖项外，仍有资格获得最佳人气奖。',
    ],
    [
      'Q4：如何提交作品？',
      'A：参赛请务必先报名，报名成功后将收到参赛邮件，按照邮件内的要求提交作品即可。作品命名格式为“作品名_作者名”，作者名务必要与报名时填写的姓名一致。2023/03/08之前都可以提交，以最后上传版本为准，会覆盖之前内容。',
    ],
    [
      'Q5：最佳人气奖将如何评选？',
      'A：最终入围作品将于比赛截至后的7天内公布，入围作品将进行网络公开投票，得票最高者将获得最佳人气奖。入围作品公布及投票的具体安排，请关注“Renderbus瑞云渲染”微信公众号。',
    ],
    [
      'Q6：比赛期间，我是否可以将参赛作品发布到除B站外的其他互联网平台吗？',
      'A：可以，发布时请在作品内标明该作品为“作者名_作品名| 第二届瑞云渲染动画创作大赛参赛作品”，并@瑞云官方账号“Renderbus瑞云渲染”（微博/抖音/视频号）。',
    ],
    [
      'Q7：获奖后将如何通知？',
      'A：获奖通知将在最终结果公示后7个工作日内通过邮件方式发送至获奖作者参赛报名时预留的邮箱地址，请确认提供真实有效的联系方式。',
    ],
    [
      'Q8：如何联系大赛组委会',
      'A：可联系邮箱：RRC@rayvision.com  或者添加微信：rayvision1，工作人员将尽快与您取得联系。',
    ],
    [
      'Q9：如何获得大赛的技术支持？',
      'A：可加入我们的赛事交流微信群，群内有相关的技术人员进行技术相关的指导和答疑。',
    ],
    [
      'Q10：如何关注大赛的最新动态？',
      'A：请关注微信公众号“Renderbus瑞云渲染”，我们将在公众号中随时公布大赛的最新动态。',
    ],
  ]
  const initForm = {
    name: '',
    phone: '',
    email: '',
    wechat: '',
    group: '专业组',
    organization: '',
    position: '',
    business: '',
    infoFrom: '',
    school: '',
    major: '',
  }
  const [formData, setFormData] = useState({ ...initForm })
  const [formValid, setFormValid] = useState(false)
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const [showPCMenu, setShowPCMenu] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [disabled, setDisabled] = useState(true)
  const [showPositionOptions, setShowPositionOptions] = useState(false)
  const [showBusinessOptions, setShowBusinessOptions] = useState(false)
  const [isDialogVisible, setIsDialogVisible] = useState(false)
  const [checkAgree, setCheckAgree] = useState(false)
  const [checkAgree2, setCheckAgree2] = useState(false)
  const [submiting, setSubmiting] = useState(false)
  const [showUploadEmailTip, setShowUploadEmailTip] = useState(false)
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [uploadResult, setUploadResult] = useState(null)
  const [signUpParams, setSignUpParams] = useState(null)
  const [uploader, setUploader] = useState(null)
  const [tempScrollbar, setTempScrollbar] = useState(null)
  const [currentIndex, setCurrentIndex] = useState('#rc-top')
  const [awardType, setAwardType] = useState('专业组')
  const [pcJudgeProfileIndex, setPcJudgeProfileIndex] = useState(0)
  const [mbJudgeProfileIndex, setMbJudgeProfileIndex] = useState(0)
  // eslint-disable-next-line no-unused-vars
  const [pcExpertProfileIndex, setPcExpertProfileIndex] = useState(0)
  const [mbExpertProfileIndex, setMbExpertProfileIndex] = useState(0)
  const [expandJudgeIndex, setExpandJudageIndex] = useState(1)
  const [expandExpertIndex, setExpandExpertIndex] = useState(-1)
  const [videoPlaying, setVideoPlaying] = useState(false)
  const [contentTag, setContentTag] = useState('评选标准')
  const [designTag, setDesignTag] = useState('参赛须知')
  const pageContainer = useRef(null)
  const video = useRef(null)

  const data = useStaticQuery(graphql`
    query {
      banner2Bg: file(relativePath: { eq: "render-contest-2023/banner2-bg.png" }) {
        ...fluidImage
      }
    }
  `)
  function validateInput(key, value, id) {
    if (!id) return
    const inputElement = document.querySelector(id)
    const _value = value || ''
    const pattern = {
      email: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/g,
      phone: /^[0-9]*$/g,
    }[key]
    const notValid = !_value || (pattern && !pattern.test(_value))
    if (notValid) {
      inputElement.style.borderColor = 'red'
      setFormValid(false)
    } else {
      inputElement.style.borderColor = ''
      setFormValid(true)
    }
    return !notValid
  }
  function setFormDataField(key, value, id) {
    setFormData({
      ...formData,
      [key]: value,
    })
    validateInput(key, value, id)
  }
  function handleScroll() {
    const achorNode = [...pageContainer.current.children].filter(node => node.id.includes('rc-'))
    for (let node of achorNode) {
      const offset = node.getBoundingClientRect()
      if (node.id.includes('rc-top')) {
        if (Math.abs(offset.top) > 74) {
          setShowPCMenu(true)
        } else {
          setShowPCMenu(false)
          setCurrentIndex('#rc-top')
        }
      } else {
        if (offset.top <= window.innerHeight && offset.bottom >= 0) {
          setCurrentIndex(`#${node.id}`)
          break
        }
      }
    }
  }

  function handleSubmit() {
    if (submiting) return
    setSubmiting(true)
    axios({
      method: 'post',
      url: 'https://www.renderbus.com/rc/sign-for-contest',
      data: signUpParams,
    })
      .then(response => {
        const { data } = response
        if (data.code === 200) {
          setSubmitSuccess(true)
        } else {
          setSubmitSuccess(false)
        }
      })
      .catch(err => {
        setSubmitSuccess(false)
      })
      .finally(() => {
        setIsDialogVisible(true)
        setSubmiting(false)
      })
  }
  useScroll(handleScroll)
  useEffect(() => {
    const params = { ...formData }
    let submitable = true
    if (formData.group === '学生组') {
      Reflect.deleteProperty(params, 'organization')
      Reflect.deleteProperty(params, 'position')
      Reflect.deleteProperty(params, 'business')
      submitable = submitable && uploadResult && uploadResult.success
    } else {
      Reflect.deleteProperty(params, 'school')
      Reflect.deleteProperty(params, 'major')
    }
    if (formData.email) validateInput('email', formData.email, '#email')
    if (formData.phone) validateInput('phone', formData.phone, '#phone')
    const hasNull = Reflect.ownKeys(params).some(key => !params[key])
    submitable = submitable && !hasNull && checkAgree && checkAgree2 && formValid
    if (submitable) {
      setSignUpParams(params)
    }
    setDisabled(!submitable)
  }, [formData, uploadResult, checkAgree, checkAgree2, formValid])
  useEffect(() => {
    if (formData.group === '学生组' && !validateInput('email', formData.email, '#email')) {
      setShowUploadEmailTip(true)
    } else {
      setShowUploadEmailTip(false)
    }
    if (formData.group === '专业组' || !validateInput('email', formData.email, '#email')) {
      if (uploader) {
        uploader.destroy()
        setUploader(null)
      }
      return
    }
    const emailHex = CryptoJS.SHA1(formData.email).toString()
    const token = CryptoJS.SHA1(`${formData.email}|${emailHex.substring(16, 32)}`).toString()
    const uploadSignatureURL = `https://www.renderbus.com/rc/oss-signature/?isWork=0&email=${formData.email}&token=${token}`
    if (uploader) {
      uploader.setUploadSignatureURL(uploadSignatureURL)
      uploader.refresh()
      return
    }
    const _uploader = new Plupload({
      browseButtonId: 'browse-button',
      dragElementId: 'upload-container',
      uploadAfterAdd: true,
      uploadSignatureURL,
      filters: {
        mime_types: 'image/*',
        max_file_size: '5mb',
      },
    })
    _uploader.setFilesAddedCallback((up, file) => {
      setUploadResult(null)
      const fileNameHex = CryptoJS.SHA1(formData.email).toString()
      const suffix = file.name.includes('.') ? file.name.split('.').pop() : ''
      file.name = suffix ? `${fileNameHex}.${suffix}` : fileNameHex
    })
    _uploader.setFilesUploadedCallback((up, file, info) => {
      if ([200, 203].includes(info.status)) {
        setUploadResult({
          success: true,
          fail: false,
        })
      } else {
        setUploadResult({
          success: false,
          fail: true,
        })
      }
    })
    _uploader.setFilesUploadErrorHandler((up, err) => {
      if (err) {
        setUploadResult({
          success: false,
          fail: true,
        })
      }
    })
    _uploader && _uploader.init()
    setUploader(_uploader)
  }, [formData.email, formData.group, uploader])
  useEffect(() => {
    function handleVideoPlay(e) {
      setVideoPlaying(!e.target.paused)
    }
    const videoElement = video.current
    if (videoElement) {
      videoElement.addEventListener('pause', handleVideoPlay)
      videoElement.addEventListener('play', handleVideoPlay)
    }
    return () => {
      if (videoElement) {
        videoElement.removeEventListener('pause', handleVideoPlay)
        videoElement.addEventListener('play', handleVideoPlay)
      }
    }
  }, [])
  function createScrollbar(id, destroy) {
    if (destroy && tempScrollbar) {
      tempScrollbar.destroy()
      setTempScrollbar(null)
      return
    }
    if (tempScrollbar) return
    const ps = new PerfectScrollbar(id)
    ps.update()
    setTempScrollbar(ps)
  }
  useEffect(() => {
    const qaContainer = document.querySelector('#qa-scroll')
    let qaScroll = new PerfectScrollbar(qaContainer)
    function handleElementScroll(e) {
      if (window.innerWidth < 600) {
        qaScroll.destroy()
        qaScroll = null
      }
    }
    qaContainer.addEventListener('ps-y-reach-end', handleElementScroll)
    return () => {
      qaContainer.removeEventListener('ps-y-reach-end', handleElementScroll)
      qaScroll && qaScroll.destroy()
      qaScroll = null
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <React.Fragment>
      <Helmet>
        <script>
          {`
              var _hmt = _hmt || [];
              (function() {
                var hm = document.createElement("script");
                hm.src = "https://hm.baidu.com/hm.js?695fad530c8ccd7ba407ec0f01da7817";
                var s = document.getElementsByTagName("script")[0];
                s.parentNode.insertBefore(hm, s);
              })();
            `}
        </script>
      </Helmet>
      <SEO
        title='第二届3D渲染动画创作大赛｜算联视界,元生万象 - 瑞云科技'
        keywords='3d渲染动画大赛, 3D渲染大赛,cg动画大赛,动画创作大赛'
        description='赢现金奖品,专业评委点评,媒体曝光,为自己代言！由瑞云科技与贵安新区科创产业发展有限公司联合主办的第二届3D渲染动画创作大赛-算联视界,元生万象火热进行中,赶紧报名投稿~'
        shareImgSrc='//rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/assets/banner.png'
      />
      <RCHeader>
        <HeaderLogo />
        {headerItemList.map(item => (
          <HeaderItem
            key={item.label}
            href={item.link}
            rel={item.rel}
            target={item.target}
            left={item.left}
            onClick={() => {
              setCurrentIndex(item.link)
            }}
          >
            {item.label}
          </HeaderItem>
        ))}
        {/* <SignButton href='#rc-form'>报名参加</SignButton> */}
        <SignButton href='/rc2022/' id='rc-entrylist'>
          参赛作品展
        </SignButton>
        <NavigateMenuIcon
          isActive={!showMobileMenu}
          onClick={() => setShowMobileMenu(!showMobileMenu)}
        />
        <NavigateMenu isActive={showMobileMenu} showPCMenu={showPCMenu}>
          <RCLeftDays>
            {(
              (new Date(2023, 2, 8, 23, 59, 59).getTime() - new Date().getTime()) /
              (24 * 60 * 60 * 1000)
            ).toFixed()}
            <p>DAYS</p>
          </RCLeftDays>
          <SignButton as={Link} to='/rc2022/'>
            作品展示
          </SignButton>
          {mobileMenuItemList.map(item => (
            <NavigateMenuItem
              href={item.link}
              key={item.label}
              onClick={() => {
                setCurrentIndex(item.link)
                setShowMobileMenu(false)
              }}
              isActive={currentIndex === item.link}
            >
              {item.label}
            </NavigateMenuItem>
          ))}
        </NavigateMenu>
      </RCHeader>
      <PageContainer ref={pageContainer}>
        <IndexSection id='rc-top'>
          <RCBanner className='rc-logo' isMain={false} style={{ maxWidth: '100vw' }} />
          <RCOrganization>
            <div className='banner-content'>
              <h1 className='title'>第二届3D渲染动画创作大赛</h1>
              <div className='slogon'>
                <img src={SlogoIcon} alt='' />
                <p className='date'>2022.12.8-2023.3.8（UTC+8）</p>
              </div>
            </div>
            <Organizer>
              <span>主办方</span>
              <div>
                <img className='rayvision' src={RayvisionIcon} alt='' />
                <img className='guian' src={GuianIcon} alt='' />
                <img className='guiyang' src={GuiYang} alt='' />
              </div>
            </Organizer>
            <CoOrganizer>
              <span>协办方</span>
              <img className='renderbus' src={RenderbusIcon} alt='' />
              <img className='qingjiao' src={QingjiaoIcon} alt='' />
              <img className='three-cat' src={ThreeDCatIcon} alt='' />
            </CoOrganizer>
            <Sponsor>
              <span>赞助方</span>
              <div>
                <img className='reallusion' src={ReallusionIcon} alt='' />
                <img className='eco-plants' src={EcoPlantsIcon} alt='' />
                <br />
                <img className='keni' src={KeniIcon} alt='' />
                <img className='choos' src={ChoosIcon} alt='' />
                <img className='d5' src={D5Icon} alt='' />
                <img className='yun' src={YunIcon} alt='' />
                <img className='fusion' src={FusionIcon} alt='' />
                <img className='sugon hideInPC' src={SugonIcon} alt='' />
                <img className='sugon hideInMobile' src={SugonIcon} alt='' />
                <img className='style-3d' src={Style3DIcon} alt='' />
              </div>
            </Sponsor>
            <SupportUnits>
              <span>支持单位</span>
              <div className='hideInPC'>
                <div className='left'>
                  {leftUnits.map(unit => (
                    <span key={unit}>{unit}</span>
                  ))}
                </div>
                <div className='right'>
                  {rightUnits.map(unit => (
                    <span key={unit}>{unit}</span>
                  ))}
                </div>
              </div>
              <div className='hideInMobile'>
                {supportUnits.slice(0, 5).map(unit => (
                  <span key={unit}>{unit}</span>
                ))}
              </div>
              <div className='second hideInMobile'>
                {supportUnits.slice(5).map(unit => (
                  <span key={unit}>{unit}</span>
                ))}
              </div>
            </SupportUnits>
          </RCOrganization>
        </IndexSection>
        <ImgWrapper>
          <Billboard2 fluid={data.banner2Bg.childImageSharp.fluid} alt='' />
        </ImgWrapper>
        <RCResultSection id='rc-result'>
          <Exibition
            groups={[
              {
                name: '专业组',
                group: professions,
              },
              {
                name: '学生组',
                group: students,
              },
              {
                name: '万生华态特别奖',
                group: wanhuaSpecial,
              },
              {
                name: 'Style3D特别奖',
                group: style3D,
              },
              {
                name: '最佳人气奖',
                group: mostPopular,
              },
            ]}
          />
        </RCResultSection>
        <RCWordsSection id='rc-words'>
          <img src={SectionTitleIcon} alt='' />
          <h2>引言</h2>
          <img className='top-bg' src={WordsBgImg} alt='' />
          <div className='words-container'>
            {words.map(arr => (
              <div className='words-group' key={arr[0]}>
                {arr.map(p => (
                  <p className='word' key={p}>
                    {p}
                  </p>
                ))}
              </div>
            ))}
          </div>
        </RCWordsSection>
        <RCDateSection id='rc-time'>
          <img src={SectionTitleIcon} alt='' />
          <h2>大赛时间</h2>
          <div className='dates-container'>
            <div>
              <span>作品征集期</span>
              <p>2022/12/08</p>-<p>2023/03/08</p>
            </div>
            <div className='judge-date'>
              <span>评选时间</span>
              <p>2023/03/09</p>-<p>2023/03/25</p>
            </div>
            <div className='result-date'>
              <span>赛果公布</span>
              <p>2023/03/31</p>
            </div>
          </div>
        </RCDateSection>
        <RCGroupSection id='rc-group'>
          <img src={SectionTitleIcon} alt='' />
          <h2>大赛组别</h2>
          <div className='group-container'>
            <div className='group'>
              <img className='professional' src={ProfessionalIcon} alt='' />
              <div>
                <span>专业组</span>
                <p>面向所有CG艺术家、CG从业者、CG爱好者</p>
              </div>
            </div>
            <div className='group'>
              <img className='student' src={StudentIcon} alt='' />
              <div>
                <span>学生组</span>
                <p>针对在校学生，报名该组别需提交学生证</p>
              </div>
            </div>
          </div>
        </RCGroupSection>
        <RCAwardSection id='rc-award'>
          <img src={SectionTitleIcon} alt='' />
          <h2>大赛奖品</h2>
          <div className='award-container'>
            <div className='award-type'>
              {[...awardGroupMap.keys()].map(type => (
                <AwardType
                  key={type}
                  isActive={awardType === type}
                  onClick={() => setAwardType(type)}
                  onMouseEnter={() => setAwardType(type)}
                >
                  {type}
                </AwardType>
              ))}
            </div>
            {awardGroupMap.get(awardType).map(item => (
              <div className={`award ${item.class ? item.class : ''}`} key={item.other[0]}>
                <span className='title'>{item.title}</span>
                {item.explain && <span className='explain'>{item.explain}</span>}
                {item.value && <span className='value'>奖品总价{item.value}</span>}
                {item.other &&
                  item.other.map(o => (
                    <span className='other' key={o}>
                      {o}
                    </span>
                  ))}
              </div>
            ))}
          </div>
        </RCAwardSection>
        <RCJudgesSection id='rc-judges'>
          <img src={SectionTitleIcon} alt='' />
          <h2>评委阵容</h2>
          <ProfileContainer className='main'>
            <span>大赛主评委</span>
            <LeftArrow
              className={pcJudgeProfileIndex === 0 ? 'hide' : 'hideInMobile'}
              onClick={() => {
                setPcJudgeProfileIndex(pcJudgeProfileIndex - 1)
                setExpandJudageIndex(pcJudgeProfileIndex)
              }}
            />
            {mainJudgesList
              .slice(pcJudgeProfileIndex, pcJudgeProfileIndex + 4)
              .map((judge, index) => (
                <Profile
                  className='hideInMobile expand'
                  key={judge.name}
                  img={judge.img}
                  imgHover={judge.imgHover}
                  isActive={expandJudgeIndex === index + pcJudgeProfileIndex}
                  onMouseEnter={() => setExpandJudageIndex(index + pcJudgeProfileIndex)}
                  onMouseLeave={() => setExpandJudageIndex(index)}
                >
                  <img
                    className='hideInMobile'
                    src={judge.img}
                    style={{
                      display: expandJudgeIndex === index + pcJudgeProfileIndex ? 'none' : 'block',
                    }}
                    alt={judge.name}
                  />
                  <img
                    className='hideInMobile'
                    src={judge.imgHover}
                    style={{
                      display: expandJudgeIndex === index + pcJudgeProfileIndex ? 'block' : 'none',
                    }}
                    alt={judge.name}
                  />
                  <p className='name'>{judge.name}</p>
                  <div className='intros'>
                    {judge.shortIntro.map(intro => (
                      <p className='intro short' key={intro}>
                        {intro}
                      </p>
                    ))}
                    {judge.intro.map(intro => (
                      <p className='intro total hide' key={intro}>
                        {intro}
                      </p>
                    ))}
                  </div>
                </Profile>
              ))}
            <RightArrow
              className={
                pcJudgeProfileIndex === mainJudgesList.length - 4 ? 'hide' : 'hideInMobile'
              }
              onClick={() => {
                setPcJudgeProfileIndex(pcJudgeProfileIndex + 1)
                setExpandJudageIndex(pcJudgeProfileIndex + 1)
              }}
            />
            <LeftArrow
              className={mbJudgeProfileIndex === 0 ? 'hide' : 'hideInPC'}
              onClick={() => {
                setMbJudgeProfileIndex(mbJudgeProfileIndex - 1)
                setExpandJudageIndex(mbJudgeProfileIndex)
              }}
            />
            {mainJudgesList
              .slice(mbJudgeProfileIndex, mbJudgeProfileIndex + 2)
              .map((judge, index) => (
                <Profile
                  className='hideInPC expand'
                  key={judge.name}
                  img={judge.img}
                  imgHover={judge.imgHover}
                  isActive={expandJudgeIndex === index + mbJudgeProfileIndex}
                  onMouseEnter={() => setExpandJudageIndex(index + mbJudgeProfileIndex)}
                  onTouchStart={() => setExpandJudageIndex(index + mbJudgeProfileIndex)}
                >
                  <p className='name'>{judge.name}</p>
                  <div className='intros'>
                    {judge.shortIntro.map(intro => (
                      <p className='intro short' key={intro}>
                        {intro}
                      </p>
                    ))}
                    {judge.intro.map(intro => (
                      <p className='intro total hide' key={intro}>
                        {intro}
                      </p>
                    ))}
                  </div>
                </Profile>
              ))}
            <RightArrow
              className={mbJudgeProfileIndex === mainJudgesList.length - 2 ? 'hide' : 'hideInPC'}
              onClick={() => {
                setMbJudgeProfileIndex(mbJudgeProfileIndex + 1)
                setExpandJudageIndex(mbJudgeProfileIndex + 1)
              }}
            />
          </ProfileContainer>
          <ProfileContainer
            className='expert'
            width='1097px'
            height='360px'
            mbWidth='72vw'
            mbHeight='48vw'
            mbPadding='0 13.46vw 0 14.53vw'
          >
            <span>专家委员会</span>
            {expertList
              .slice(pcExpertProfileIndex, pcExpertProfileIndex + 4)
              .map((expert, index) => (
                <Profile
                  className='hideInMobile'
                  key={expert.name}
                  img={expert.img}
                  width='260px'
                  onMouseEnter={() => setExpandExpertIndex(index + pcExpertProfileIndex)}
                  onMouseLeave={() => setExpandExpertIndex(-1)}
                  onTouchStart={() => setExpandExpertIndex(index + pcExpertProfileIndex)}
                >
                  <img className='hideInMobile' src={expert.img} alt={expert.name} />
                  <p className='name'>{expert.name}</p>
                  <div className='intros'>
                    {expandExpertIndex !== index + pcExpertProfileIndex &&
                      expert.intros.shortIntro.map(intro => (
                        <p className='intro' key={intro}>
                          {intro}
                        </p>
                      ))}
                    {expandExpertIndex === index + pcExpertProfileIndex &&
                      expert.intros.total.map(intro => (
                        <p className='intro' key={intro}>
                          {intro}
                        </p>
                      ))}
                  </div>
                </Profile>
              ))}
            <LeftArrow
              className={mbExpertProfileIndex === 0 ? 'hide' : 'hideInPC'}
              onClick={() => {
                setMbExpertProfileIndex(mbExpertProfileIndex - 1)
                setExpandExpertIndex(mbExpertProfileIndex)
              }}
            />
            {expertList
              .slice(mbExpertProfileIndex, mbExpertProfileIndex + 2)
              .map((expert, index) => (
                <Profile
                  className='hideInPC'
                  key={expert.name}
                  img={expert.img}
                  mbWidth='34.66vw'
                  onMouseEnter={() => setExpandExpertIndex(index + mbExpertProfileIndex)}
                  onTouchStart={() => setExpandExpertIndex(index + mbExpertProfileIndex)}
                >
                  <p className='name'>{expert.name}</p>
                  <div className='intros'>
                    {expandExpertIndex !== index + mbExpertProfileIndex &&
                      expert.intros.shortIntro.map(intro => (
                        <p className='intro' key={intro}>
                          {intro}
                        </p>
                      ))}
                    {expandExpertIndex === index + mbExpertProfileIndex &&
                      expert.intros.total.map(intro => (
                        <p className='intro' key={intro}>
                          {intro}
                        </p>
                      ))}
                  </div>
                </Profile>
              ))}
            <RightArrow
              className={mbExpertProfileIndex === expertList.length - 2 ? 'hide' : 'hideInPC'}
              onClick={() => {
                setMbExpertProfileIndex(mbExpertProfileIndex + 1)
                setExpandExpertIndex(mbExpertProfileIndex + 1)
              }}
            />
          </ProfileContainer>
          <ProfileContainer
            className='expert hideInMobile'
            width='1097px'
            height='360px'
            mbWidth='72vw'
            mbHeight='48vw'
            mbPadding='0 13.46vw 0 14.53vw'
          >
            {expertList.slice(pcExpertProfileIndex + 4).map((expert, index) => (
              <Profile
                className='hideInMobile'
                key={expert.name}
                img={expert.img}
                width='260px'
                onMouseEnter={() => setExpandExpertIndex(index + pcExpertProfileIndex + 4)}
                onMouseLeave={() => setExpandExpertIndex(-1)}
                onTouchStart={() => setExpandExpertIndex(index + pcExpertProfileIndex + 4)}
              >
                <img className='hideInMobile' src={expert.img} alt={expert.name} />
                <p className='name'>{expert.name}</p>
                <div className='intros'>
                  {expandExpertIndex !== index + pcExpertProfileIndex + 4 &&
                    expert.intros.shortIntro.map(intro => (
                      <p className='intro' key={intro}>
                        {intro}
                      </p>
                    ))}
                  {expandExpertIndex === index + pcExpertProfileIndex + 4 &&
                    expert.intros.total.map(intro => (
                      <p className='intro' key={intro}>
                        {intro}
                      </p>
                    ))}
                </div>
              </Profile>
            ))}
          </ProfileContainer>
        </RCJudgesSection>
        <RCContentSection id='rc-content'>
          <img src={SectionTitleIcon} alt='' />
          <h2>大赛内容</h2>
          <div className='content'>
            <div
              className='video-container'
              onClick={() => {
                if (video.current.paused) {
                  video.current.play()
                } else {
                  video.current.pause()
                }
              }}
            >
              <video ref={video} preload='true' playsInline>
                <source src='https://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/assets/3D%E6%A8%A1%E6%9D%BF%E8%A7%86%E9%A2%91%E5%B1%95%E7%A4%BA.mov' />
              </video>
              <img src={PauseImg} alt='' className={videoPlaying ? 'hide' : ''} />
            </div>
            <div className='text-container'>
              <p>用7秒钟展现精彩创意</p>
              <span>
                1.以大赛发放的3D动画模板场景为基础，进行创意制作，动画总计时长7秒整。创作时必须保证最终圆形外轮廓大小、位置不变（和原FBX或USD一致），并让它贯穿整个作品，场景内其他地方可任意发挥。作品不限主题和风格，你可以在场景内自由添加任何主体或元素来丰富它，只要最后提交的是一个完整的3D动画渲染作品即可。
              </span>
              <span>
                2. 作品画面风格不限，允许写实、卡通、水墨……渲染呈现出来的任何画风，都可接受；
              </span>
              <span>
                3.
                题材遵守“四不原则”：不违法，不三俗，不侵权，不惹事（不违反中国法律；不庸俗、低俗、媚俗；不侵犯他人合法权益；不涉及意识形态等争议话题）。
              </span>
              <a
                download='大赛资料包 - 第二届瑞云3D渲染动画创作大赛.rar'
                href='https://www.renderbus.com/rrc/%E5%A4%A7%E8%B5%9B%E8%B5%84%E6%96%99%E5%8C%85%20-%20%E7%AC%AC%E4%BA%8C%E5%B1%8A%E7%91%9E%E4%BA%913D%E6%B8%B2%E6%9F%93%E5%8A%A8%E7%94%BB%E5%88%9B%E4%BD%9C%E5%A4%A7%E8%B5%9B.rar'
              >
                <img src={DownloadImg} alt='' />
                下载大赛资料
              </a>
            </div>
          </div>
          <div className='other'>
            <LeftTagsContainer>
              {contentTags.map(tag => (
                <Tag
                  key={tag}
                  isActive={contentTag === tag}
                  onMouseEnter={() => setContentTag(tag)}
                  onClick={() => setContentTag(tag)}
                >
                  {tag}
                </Tag>
              ))}
            </LeftTagsContainer>
            {contentTag === '评选标准' && (
              <div className='standar-container'>
                {standarList.map(standar => (
                  <div key={standar.title} className='standar'>
                    <p>{standar.title}</p>
                    <span>{standar.text}</span>
                  </div>
                ))}
              </div>
            )}
            {contentTag === '技术支持' && (
              <div className='support-container'>
                {techSupports.map(support => (
                  <div key={support.title} className='support'>
                    <p className='title'>{support.title}</p>
                    <p className='subTitle'>{support.subTitle}</p>
                    {support.dowloadLink && (
                      <a href={support.dowloadLink.link} className='download'>
                        <img src={DownloadImg} alt='' />
                        {support.dowloadLink.text}
                      </a>
                    )}
                    {support.aboutLink && (
                      <a href={support.aboutLink.link} className={`go ${support.aboutLink.class}`}>
                        {support.aboutLink.text}
                        <img src={GoImg} alt='' />
                      </a>
                    )}
                    {support.useLink && (
                      <a href={support.aboutLink.link} className='usage'>
                        {support.useLink.text}
                      </a>
                    )}
                  </div>
                ))}
              </div>
            )}
            {contentTag === '赛事交流' && (
              <div className='exchange-container'>
                <p className='title'>赛事交流</p>
                <span>想跟技术大咖们学习CG技巧吗？</span>
                <span>想和CG小伙伴们交流创作经验吗？</span>
                <span>欢迎加入我们的赛事交流群，一起玩转创意！</span>
                <span>如有任何关于赛事的疑问，也可以联系大赛主办工作人员RRC@rayvision.com</span>
                <div>
                  <img src={QRImg} alt='' />
                  <div>
                    <p>扫码添加小助手微信</p>
                    <p>
                      或微信添加<span>rayvision1</span>
                    </p>
                    <p>
                      备注<span>“渲染大赛”</span>
                    </p>
                  </div>
                </div>
              </div>
            )}
            {contentTag === '获奖通知' && (
              <div className='notice-container'>
                <p>获奖通知</p>
                {awardNotices.map(notice => (
                  <span key={notice}>{notice}</span>
                ))}
              </div>
            )}
            {contentTag === '相关权益' && (
              <div className='profit-container'>
                <p>相关权益</p>
                {relatedProfitList.map(notice => (
                  <span key={notice}>{notice}</span>
                ))}
              </div>
            )}
          </div>
        </RCContentSection>
        <RCDesignSection id='rc-design'>
          <img src={SectionTitleIcon} alt='' />
          <h2>设计说明</h2>
          <div className='design-container'>
            <LeftTagsContainer>
              {[...designInfo.keys()].map(tag => (
                <Tag
                  key={tag}
                  isActive={designTag === tag}
                  onMouseEnter={() => setDesignTag(tag)}
                  onClick={() => setDesignTag(tag)}
                >
                  {tag}
                </Tag>
              ))}
            </LeftTagsContainer>
            <div className='content'>
              <p>{designTag}</p>
              {designInfo.get(designTag).map(item => (
                <span key={item}>{item}</span>
              ))}
            </div>
          </div>
        </RCDesignSection>
        <RCQASection id='rc-qa'>
          <img src={SectionTitleIcon} alt='' />
          <h2>常见问题</h2>
          <div className='q-container' id='qa-scroll'>
            {qaList.map((qa, index) => (
              <div key={qa[0]} className={(index + 1) % 2 === 0 ? 'even' : 'odd'}>
                <p>{qa[0]}</p>
                <p>{qa[1]}</p>
              </div>
            ))}
          </div>
        </RCQASection>
        <RCFormSection id='rc-form' style={{ display: 'none' }}>
          <RCSignFormContainer>
            <h2>参赛报名表</h2>
            <RCSignForm>
              <RCFormItem bottom='19'>
                <RCLabel left='301' right='11' className='name'>
                  *您的姓名
                </RCLabel>
                <RCInput
                  value={formData.name}
                  id='name'
                  onChange={e => setFormDataField('name', e.target.value, '#name')}
                />
                <br className='hideInPC' />
                <RCLabel left='47' right='9' className='phone'>
                  *您的电话
                </RCLabel>
                <RCInput
                  type='tel'
                  value={formData.phone}
                  id='phone'
                  onChange={e => setFormDataField('phone', e.target.value, '#phone')}
                />
              </RCFormItem>
              <RCFormItem bottom='29'>
                <RCLabel left='301' right='11' className='email'>
                  *您的邮箱
                </RCLabel>
                <RCInput
                  value={formData.email}
                  placeholder='接收作品提交邮件'
                  id='email'
                  onChange={e => setFormDataField('email', e.target.value, '#email')}
                />
                <br className='hideInPC' />
                <RCLabel left='36' right='8' className='wechat'>
                  *您的微信号
                </RCLabel>
                <RCInput
                  value={formData.wechat}
                  placeholder='非微信昵称'
                  id='wechat'
                  onChange={e => setFormDataField('wechat', e.target.value, '#wechat')}
                />
              </RCFormItem>
              <RCFormItem bottom='28' style={{ display: 'flex' }}>
                <RCLabel left='299' right='8' className='group'>
                  *参赛组别
                </RCLabel>
                <RCRadioContainer left='49'>
                  <RCRadio
                    type='radio'
                    value='专业组'
                    checked={'专业组' === formData.group}
                    onChange={e => setFormDataField('group', '专业组')}
                  />
                  <span className='expert'>专业组</span>
                  <RCRadio
                    type='radio'
                    value='学生组'
                    left='99'
                    checked={'学生组' === formData.group}
                    onChange={e => setFormDataField('group', '学生组')}
                  />
                  <span>学生组</span>
                </RCRadioContainer>
              </RCFormItem>
              {formData.group === '专业组' && (
                <>
                  <RCFormItem bottom='19' className='organization'>
                    <RCLabel left='210' right='11' className='organization'>
                      *您所在公司名/组织机构
                    </RCLabel>
                    <RCInput
                      value={formData.organization}
                      width='521'
                      id='organization'
                      onChange={e =>
                        setFormDataField('organization', e.target.value, '#organization')
                      }
                    />
                  </RCFormItem>
                  <RCFormItem bottom='19' className='position'>
                    <RCLabel left='257' right='10' className='position'>
                      *您所担任的职务
                    </RCLabel>
                    <RCSelectContainer>
                      <RCSelectIcon isActive={showPositionOptions} />
                      <RCSelect
                        readOnly
                        value={formData.position}
                        id='position'
                        onClick={() => {
                          setShowBusinessOptions(false)
                          setShowPositionOptions(!showPositionOptions)
                        }}
                      />
                      <RCOptionContainer
                        isActive={showPositionOptions}
                        id='position-scroll'
                        onMouseEnter={() => createScrollbar('#position-scroll')}
                        onMouseLeave={() => createScrollbar(undefined, true)}
                      >
                        {positionOptions.map(option => (
                          <RCOption
                            key={option}
                            value={option}
                            isActive={option === formData.position}
                            onClick={() => {
                              setShowPositionOptions(false)
                              setFormDataField('position', option, '#position')
                            }}
                          >
                            {option}
                          </RCOption>
                        ))}
                      </RCOptionContainer>
                    </RCSelectContainer>
                  </RCFormItem>
                  <RCFormItem bottom='29' className='business'>
                    <RCLabel left='272' right='8' className='business'>
                      *您所在的行业
                    </RCLabel>
                    <RCSelectContainer>
                      <RCSelectIcon isActive={showBusinessOptions} />
                      <RCSelect
                        readOnly
                        value={formData.business}
                        id='business'
                        onClick={() => setShowBusinessOptions(!showBusinessOptions)}
                      />
                      <RCOptionContainer
                        isActive={showBusinessOptions}
                        id='business-scroll'
                        onMouseEnter={() => createScrollbar('#business-scroll')}
                        onMouseLeave={() => createScrollbar(undefined, true)}
                      >
                        {businessOptions.map(option => (
                          <RCOption
                            key={option}
                            value={option}
                            isActive={option === formData.business}
                            onClick={() => {
                              setShowBusinessOptions(false)
                              setFormDataField('business', option, '#business')
                            }}
                          >
                            {option}
                          </RCOption>
                        ))}
                      </RCOptionContainer>
                    </RCSelectContainer>
                  </RCFormItem>
                </>
              )}
              {formData.group === '学生组' && (
                <>
                  <RCFormItem bottom='29' className='student-card'>
                    <StudentCardContainer>
                      <RCLabel right='10'>*请上传学生证</RCLabel>
                      <RCUploadContainer id='upload-container'>
                        <img className='upload' src={AddFileImg} alt='' />
                        <p>
                          拖拽文件到这里或<span id='browse-button'>点此添加</span>
                        </p>
                        <p className='tip'>（仅限小于5Mb的jpg格式文件）</p>
                        {uploadResult && uploadResult.success && (
                          <div>
                            <img className='result' src={UploadSuccessIcon} alt='' />
                            <span className='success'>上传成功！</span>
                          </div>
                        )}
                        {uploadResult && uploadResult.fail && (
                          <div>
                            <img className='result' src={UploadFailIcon} alt='' />
                            <span className='fail'>上传失败，请重新上传</span>
                          </div>
                        )}
                        {showUploadEmailTip && (
                          <div>
                            <img className='result' src={UploadFailIcon} alt='' />
                            <span className='fail'>请先填写邮箱</span>
                          </div>
                        )}
                      </RCUploadContainer>
                    </StudentCardContainer>
                  </RCFormItem>
                  <RCFormItem bottom='19' className='school'>
                    <RCLabel left='298' right='11' className='school'>
                      *学校名称
                    </RCLabel>
                    <RCInput
                      value={formData.school}
                      width='521'
                      id='school'
                      onChange={e => setFormDataField('school', e.target.value, '#school')}
                    />
                  </RCFormItem>
                  <RCFormItem bottom='19' className='major'>
                    <RCLabel left='298' right='11' className='major'>
                      *所学专业
                    </RCLabel>
                    <RCInput
                      value={formData.major}
                      width='521'
                      id='major'
                      onChange={e => setFormDataField('major', e.target.value, '#major')}
                    />
                  </RCFormItem>
                </>
              )}
              <RCFormItem bottom='36' className='info-from'>
                <RCLabel left='269' right='8' className='info-from'>
                  *您是从哪里得知本次大赛？（单选）
                </RCLabel>
                <RCRadioContainer left='282'>
                  {infoSourceOptions.map(source => (
                    <div key={source}>
                      <RCRadio
                        type='radio'
                        value={source}
                        checked={source === formData.infoFrom}
                        onChange={e => setFormDataField('infoFrom', source, '#info-from')}
                      />
                      <span>{source}</span>
                    </div>
                  ))}
                  <div>
                    <RCRadio
                      type='radio'
                      value='其他'
                      checked={formData.infoFrom && !infoSourceOptions.includes(formData.infoFrom)}
                      onChange={e => setFormDataField('infoFrom', '其他')}
                    />
                    <span>其他：</span>
                    <RCInput
                      className='other-input'
                      id='info-from'
                      onChange={e => setFormDataField('infoFrom', e.target.value, '#info-from')}
                    />
                  </div>
                </RCRadioContainer>
              </RCFormItem>
              <RCFormItem className='agreement'>
                <RCAgreements>
                  <RCCheckbox checked={checkAgree} onClick={() => setCheckAgree(!checkAgree)} />
                  <span>
                    本人已知悉且同意诚信参赛，不弄虚作假，不恶意侵占比赛资源，若有违反，主办方将追回侵占资源费用且追究法律责任。
                  </span>
                  <br />
                  <RCCheckbox checked={checkAgree2} onClick={() => setCheckAgree2(!checkAgree2)} />
                  <span>
                    本人已知悉所有参赛者向大赛提交的参赛作品需拥有独立完整的著作权，或已经取得著作权人的合法授权。主办方及合作方拥有对全部参赛作品进行展示、报道、宣传及用于市场活动的权利，主办方及合作方有权将用于市场活动的作品加入赛事水印，参赛者保留对其作品的著作权。获奖者将获邀参与由主办方及合作方举办的相关市场推广活动。作品一经提交，将视为参赛者同意并遵守比赛相关规定。
                  </span>
                </RCAgreements>
              </RCFormItem>
              <RCButton type='button' disabled={true} onClick={handleSubmit}>
                {submiting ? '提交中' : '提交报名表'}
              </RCButton>
              <div className='qr-container'>
                <img src={QRImg} alt='' />
                <div>
                  <p>扫码添加小助手微信,加入赛事交流群!</p>
                  <p>你将在1-3个工作日收到报名邮件,可根据报名邮件提交作品。</p>
                </div>
              </div>
            </RCSignForm>
          </RCSignFormContainer>
        </RCFormSection>
        <PageFooter>
          <p>
            <img src={PoliceIcon} alt='' />© 2022
            <a href='https://www.rayvision.com/' rel='nofollow'>
              深圳市瑞云科技股份有限公司
            </a>
            <a href='https://beian.miit.gov.cn/#/Integrated/index' target='_blank' rel='nofollow'>
              粤ICP备12028569号
            </a>
          </p>
          <p>中华人民共和国增值电信业务经营许可证编号：合字 B1-20200125</p>
        </PageFooter>
        <RCDialogContainer isActive={isDialogVisible}>
          <div className='content'>
            <img src={QRImg} alt='' />
            <div className='text'>
              <p>{submitSuccess ? '恭喜你报名表提交成功！' : '糟糕！提交报名表失败！'}</p>
              <span>
                {submitSuccess
                  ? '您将在1个工作日内收到成功报名通知邮件，'
                  : '可能是因为网络原因，请您检查网络'}
              </span>
              <span>
                {submitSuccess ? '如未收到，请加大赛小助手微信反馈。' : '或者加大赛小助手微信反馈'}
              </span>
              <button onClick={() => setIsDialogVisible(false)}>我知道了</button>
            </div>
          </div>
        </RCDialogContainer>
      </PageContainer>
      <RCStyle />
    </React.Fragment>
  )
}

export default RenderContest
